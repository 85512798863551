@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,400;1,700&display=swap");

:root {
  --branded-purple: #8467ad;
  --branded-action-purple: #8c28e4;
  --branded-blue: #00508e;
  --branded-darker-gray: #333333;
  --branded-dark-gray: #22272a;
  --branded-light-gray: #ccced0;
  --branded-gray: #f2f2f2;
  --branded-error-red: #b30000;
  --branded-yellow: #f2c94c;
  --branded-validation-green: #00b298;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  background-color: var(--branded-gray);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.p-datepicker {
  border: 2px solid #8c28e4 !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.blue-gradient {
  background: rgb(20, 93, 154);
  background: linear-gradient(90deg,
      rgba(20, 93, 154, 1) 0%,
      rgba(0, 125, 211, 1) 100%);
}

.green-gradient {
  background: rgb(0, 178, 152);
  background: linear-gradient(90deg,
      rgba(0, 178, 152, 1) 0%,
      rgba(38, 66, 101, 1) 100%);
}

.btn {
  display: inline-flex;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--branded-purple);
}

.btn-sm {
  display: inline-flex;
  cursor: pointer;
  color: white;
  font-size: 0.875rem;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: var(--branded-purple);
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

button:disabled:hover,
button[disabled]:hover {
  color: white !important;
}

.custom-tooltip .p-tooltip-text {
  font-size: 13px;
  border: 1px solid #e7e0f1 !important;
  background-color: white !important;
  color: #383838 !important;
}

.custom-tooltip .p-tooltip-arrow {
  background-color: transparent !important;
  border: none !important;
}

.custom_dialog {
  padding: 25px !important;
  background-color: white !important;
}

.custom_dialog .p-dialog-header {
  border-bottom: 3px solid #e7e0f1 !important;
  padding: 0px 0px 15px 0px !important;
}

.custom_dialog .p-dialog-header-icon {
  color: #873caa !important;
  background-color: rgba(222, 190, 237, 0.5) !important;
}

.custom_dialog .p-dialog-content {
  padding: 15px 0px 0px 0px !important;
}

.custom_dialog .p-dialog-footer {
  border-top: 0 !important;
  padding: 15px 0px 0px 0px !important;
}

.p-image-mask {
  border-radius: 10px !important;
  top: 10% !important;
  left: 10% !important;
  width: 80% !important;
  height: 80% !important;
  background-color: rgb(34 39 42/0.9) !important;
}

.p-image-preview {
  max-width: 60vw !important;
  max-height: 60vh !important;
  border-radius: 10px !important;
}

.p-image-preview-toolbar .p-image-preview-button-group {
  display: none;
}

.p-image-toolbar {
  gap: 10px;
}

.p-image-toolbar .p-image-action:nth-of-type(3),
.p-image-toolbar .p-image-action:nth-of-type(4) {
  display: none !important;
}

.p-image-action.p-link {
  width: 24px !important;
  height: 24px !important;
}

.p-image-action.p-link i {
  font-size: 24px !important;
}

.custom_dialog {
  padding: 25px !important;
  background-color: white !important;
}

.custom_dialog .p-dialog-header {
  border-bottom: 3px solid #e7e0f1 !important;
  padding: 0px 0px 15px 0px !important;
}

.custom_dialog .p-dialog-header-icon {
  color: #873caa !important;
  background-color: rgba(222, 190, 237, 0.5) !important;
}

.custom_dialog .p-dialog-content {
  padding: 15px 0px 0px 0px !important;
}

.custom_dialog .p-dialog-footer {
  border-top: 0 !important;
  padding: 15px 0px 0px 0px !important;
}